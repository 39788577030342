import { request as defaultRequest } from "@octokit/request";
import btoa from "btoa-lite";
export async function scopeToken(options) {
    const { request, clientType, clientId, clientSecret, token, ...requestOptions } = options;
    const response = await (request ||
        /* istanbul ignore next: we always pass a custom request in tests */ defaultRequest)("POST /applications/{client_id}/token/scoped", {
        headers: {
            authorization: `basic ${btoa(`${clientId}:${clientSecret}`)}`,
        },
        client_id: clientId,
        access_token: token,
        ...requestOptions,
    });
    const authentication = Object.assign({
        clientType,
        clientId,
        clientSecret,
        token: response.data.token,
    }, response.data.expires_at ? { expiresAt: response.data.expires_at } : {});
    return { ...response, authentication };
}
