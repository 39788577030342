import { parseRequest } from "./parse-request";
import { sendResponse } from "./send-response";
import { handleRequest } from "../handle-request";
import { onUnhandledRequestDefault } from "../on-unhandled-request-default";
async function onUnhandledRequestDefaultCloudflare(request) {
    const octokitRequest = parseRequest(request);
    const octokitResponse = onUnhandledRequestDefault(octokitRequest);
    return sendResponse(octokitResponse);
}
export function createCloudflareHandler(app, { pathPrefix, onUnhandledRequest = onUnhandledRequestDefaultCloudflare, } = {}) {
    return async function (request) {
        const octokitRequest = parseRequest(request);
        const octokitResponse = await handleRequest(app, { pathPrefix }, octokitRequest);
        return octokitResponse
            ? sendResponse(octokitResponse)
            : await onUnhandledRequest(request);
    };
}
